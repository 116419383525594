
import { Vue, Component } from "vue-property-decorator";
import AppVersion from "../../models/app_version";
import mobileAppRoutes from "@/api/routes/mobile_app";
import Upload from "@/components/Upload.vue";

@Component({
  components: {
    Upload
  }
})
export default class VMobileAppVersions extends Vue {
  private versions: AppVersion[] = [];
  private new_version: AppVersion = new AppVersion();
  private installer_file: Blob | null = null;
  private installer_url: string | null = null;
  private show_errors = false;
  protected acceptFiles: string[] = [
    "ipa",
    "apk",
    "application/x-itunes-ipa",
    "application/vnd.android.package-archive"
  ];

  private created() {
    this.$api
      .get(mobileAppRoutes.index)
      .then(res => (this.versions = res.data));
  }

  private async uploadVersion() {
    if (!this.installer_file) {
      this.$notify({
        type: "error",
        group: "notifications",
        text: "Файл не выбран"
      });
      return;
    } else if (!this.new_version.version) {
      this.show_errors = true;
      this.$notify({
        type: "error",
        group: "notifications",
        text: "Укажите версию"
      });
      return;
    } else {
      const form_data = new FormData();
      form_data.append("installer_file", this.installer_file);
      form_data.append("version", this.new_version.version);
      form_data.append("installer_url", this.installer_url as string);

      this.versions.push(
        (await this.$api.post(mobileAppRoutes.index, form_data)).data
      );
      this.$notify({
        type: "message",
        group: "notifications",
        text: "Версия добавлена"
      });
      this.show_errors = false;
    }
  }
}
